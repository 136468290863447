import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'

export const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || ''
export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || ''

// IDが取得できない場合を想定する
export const existsGaId = GA_ID !== ''

// PVを測定する
export const pageview = (path: string) => {
  window.gtag('config', GA_ID, {
    page_path: path,
  })
}

// GAイベントを発火させる
export const event = ({ action, category, label, value = '', transport, cb }: Event) => {
  if (!existsGaId) {
    return
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label ? JSON.stringify(label) : '',
    value,
    transport_type: transport,
    event_callback: cb && cb(),
  })
}

// _app.tsx で読み込む
export const usePageView = () => {
  const router = useRouter()

  useEffect(() => {
    if (!existsGaId) {
      return
    }

    const handleRouteChange = (path: string) => {
      pageview(path)
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
}

// _app.tsx で読み込む
export const GoogleAnalytics = () => (
  <>
    {existsGaId && (
      <>
        <Script
          defer
          id="gtm-script"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
          strategy="afterInteractive"
        />
        <Script
          defer
          id="gtm-script-2"
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              if(document.cookie.indexOf('fanme_token') > 0) {
                window.dataLayer.push({'logged_in': true})
              }else{
                window.dataLayer.push({'logged_in': false})
              }
              gtag('js', new Date());
              gtag('config', '${GA_ID}');
            `,
          }}
          strategy="afterInteractive"
        />
        <Script
          defer
          id="gtag-data_layer"
          dangerouslySetInnerHTML={{
            __html: `(
              function(w,d,s,l,i){
                w[l] = w[l] || [];
                w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');
            `,
          }}
          strategy="afterInteractive"
        />
      </>
    )}
  </>
)

// イベントを型で管理
type SubmitEvent = {
  action: 'submit_form'
  category: 'information'
}

type ClickEvent = {
  action: 'click'
  category: 'other'
}

type OpenLPMenuEvent = {
  action: 'lp_menu'
  category: 'other'
}

type OpenCreatorMenu = {
  action: 'page_menu'
  category: 'other'
}

type EditPageInCreatorMenu = {
  action: 'menu_edit'
  category: 'other'
}

type MiniappsInMenu = {
  action: 'menu_miniapp'
  category: 'other'
}

type LoginMenuLoginEvent = {
  action: 'lp_menu_login'
  category: 'login'
}

type LoginFromCreatorPage = {
  action: 'page_login'
  category: 'login'
}

type RegisterLPEvent = {
  action: 'lp_newcreate'
  category: 'register'
}

type RegisterMenuSignupEvent = {
  action: 'lp_menu_newcreate'
  category: 'register'
}

type RegisterFromCreatorPage = {
  action: 'page_newcreate'
  category: 'register'
}

type CreatorPageView = {
  action: 'creator_page_view'
  category: 'page_view'
}

type CreatorPageClick = {
  action: 'click'
  category: 'creator_page_click'
}

type MiniappPageView = {
  action: 'miniapp_page_view'
  category: 'page_view'
}

type PurchaseStart = {
  action: 'purchase_start'
  category: 'purchase'
}

type PurchaseCompleted = {
  action: 'purchase_completed'
  category: 'purchase'
}

type PurchaseFailure = {
  action: 'purchase_failure'
  category: 'purchase'
}

type PurchseCanceled = {
  action: 'purchase_cancel'
  category: 'purchase'
}

type FANMEFanLetter = {
  action: 'miniapp_fanLetter'
  category: 'miniapps'
}

type FANMEAlbum = {
  action: 'miniapp_album'
  category: 'miniapps'
}
type FANMEPhotoGacha = {
  action: 'miniapp_photogacha'
  category: 'miniapps'
}

type FANMEMovie = {
  action: 'miniapp_movie'
  category: 'miniapps'
}

type FANMEVoice = {
  action: 'miniapp_voice'
  category: 'miniapps'
}

type FANMEPrintbin = {
  action: 'miniapp_printbin'
  category: 'miniapps'
}

type FANMEPrintgacha = {
  action: 'miniapp_printgacha'
  category: 'miniapps'
}

type FANMEAboutMe = {
  action: 'miniapp_aboutme'
  category: 'miniapps'
}

type FANMEWelcomeboard = {
  action: 'miniapp_welcomeboard'
  category: 'miniapps'
}

export type Event = (
  | SubmitEvent
  | ClickEvent
  | CreatorPageView
  | CreatorPageClick
  | MiniappPageView
  | PurchaseStart
  | PurchaseCompleted
  | PurchaseFailure
  | PurchseCanceled
  | RegisterLPEvent
  | OpenLPMenuEvent
  | RegisterMenuSignupEvent
  | LoginMenuLoginEvent
  | RegisterFromCreatorPage
  | LoginFromCreatorPage
  | EditPageInCreatorMenu
  | OpenCreatorMenu
  | MiniappsInMenu
  | FANMEFanLetter
  | FANMEAlbum
  | FANMEPhotoGacha
  | FANMEMovie
  | FANMEVoice
  | FANMEPrintbin
  | FANMEPrintgacha
  | FANMEAboutMe
  | FANMEWelcomeboard
) & {
  label?: Record<string, string | number | boolean> | string
  value?: string | number
  transport?: 'beacon' | 'xhr' | 'image' | 'fetch'
  cb?: () => void
}
