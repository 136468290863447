import React, { createContext, FC, useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { fetchFanmeData, postBackend } from '@/libs/fanme_backend'

interface IRankingEventContext {
  ongoingEvents: Array<IRankingEvent> // 開催中イベント
  joiningEvent: IRankingEventCreator | null // 参加中イベント
  actionable: IRankingEventActionable | null // アクション可否
  setActionable: (actionable: IRankingEventActionable | null) => void
  getOngoingRankingEvents: () => Promise<Array<IRankingEvent>> // 開催中のランキングイベントを取得
  getJoiningEvent: (force: boolean) => Promise<IRankingEventCreator | null> // 参加中のランキングイベントを取得
  getRankingEvent: (event_identity: string) => Promise<IRankingEvent | null> // 任意のランキングイベントを取得
  getCreatorRanking: (event_identity: string) => Promise<Array<IRankingEventCreator>> // クリエイターランキングを取得
  getUserRanking: (event_identity: string, creatorId: string) => Promise<Array<IRankingEventUser>> // ユーザーランキングを取得
  postYell: (
    event_identity: string,
    creatorId: string,
    logType: number,
    count: number,
  ) => Promise<number> // エールを登録
  getActionable: (
    event_identity: string,
    creatorId: string,
  ) => Promise<IRankingEventActionable | null> // アクション可否を取得
  partsicipateEvent: (event_identity: string) => Promise<boolean> // イベントに参加
  getEventStatus: (event: IRankingEvent) => RankingEventStatus // イベントのステータスを取得
  generateRouteWithViewCreator: (baseUrl: string, activeViewCreatorId?: string | null) => string
  getRankingResult: (event_identity: string) => Promise<Array<IRankingEventResult>> // ランキング結果を取得
}
const RankingEventContext = createContext<IRankingEventContext>({
  ongoingEvents: [],
  joiningEvent: null,
  actionable: null,
  setActionable: () => {},
  getOngoingRankingEvents: () => Promise.resolve([]),
  getJoiningEvent: () => Promise.resolve(null),
  getRankingEvent: () => Promise.resolve(null),
  getCreatorRanking: () => Promise.resolve([]),
  getUserRanking: () => Promise.resolve([]),
  getActionable: () => Promise.resolve(null),
  postYell: () => Promise.resolve(0),
  partsicipateEvent: () => Promise.resolve(false),
  getEventStatus: () => RankingEventStatus.Preparing,
  generateRouteWithViewCreator: () => '',
  getRankingResult: () => Promise.resolve([]),
})

export interface IRankingEvent {
  id: number
  event_identity: string
  name: string
  description: string
  image_url: string
  base_color: string | null
  add_info: string | null
  add_info_url: string | null
  add_info2: string | null
  add_info2_url: string | null
  result_add_info: string | null
  result_add_info_url: string | null
  judge_x: string | null
  judge_instagram: string | null
  share_hashtags: string | null
  results: string | null
  apply_start_at: Date
  apply_end_at: Date
  start_at: Date
  end_at: Date
  calculated_at: Date
  archived_at: Date
  active_ranking_yell_boost: IRankingYellBoost
}

export interface IRankingYellBoost {
  id: number
  ranking_event_id: number
  boost_ratio: number
  start_at: Date
  end_at: Date
}

export interface IRankingEventCreator {
  ranking_event_id: number
  creator_id: number
  comment: string
  rank: number
  yell_count: number
  aggregation_at: Date
  previous_rank: number
  previous_yell_count: number
  previous_aggregation_at: Date
  ranking_event: IRankingEvent
  creator: {
    account_identity: string
    icon: string
    is_public: boolean
    name: string
    uid: string
  }
}

export interface IRankingEventUser {
  ranking_event_creator_id: number
  user_id: number
  rank: number
  yell_count: number
  aggregation_at: Date
  previous_rank: number
  previous_yell_count: number
  previous_aggregation_at: Date
  user: {
    account_identity: string
    icon: string
    is_public: boolean
    name: string
    uid: string
  }
  ranking_fan_badge: {
    ranking_event_id: number
    rank: number
    badge_url: string
    image_url: string
  }
}

export interface IRankingEventActionable {
  can_yell_for_click: boolean
  can_yell_for_share: boolean
  can_yell_for_gacha: boolean
  today_yell_count: number
  active_boost_ratio: number // 1の倍は通常（それ以上はブースト中）
}

export interface IRankingEventResult {
  rank_key: string
  creators: Array<IRankingEventResultCreator>
}
export interface IRankingEventResultCreator {
  ranking_event_id: number
  creator_id: number
  comment: string
  rank: number
  yell_count: number
  aggregation_at: string
  previous_rank: number | null
  previous_yell_count: number | null
  previous_aggregation_at: string | null
  creator: {
    name: string
    account_identity: string
    icon: string
    uid: string
    is_public: boolean
  }
  users: Array<{
    name: string
    account_identity: string
    icon: string
    uid: string
    is_public: boolean
  }>
}

export enum RankingEventStatus {
  Preparing = 'preparing',
  AcceptingEntries = 'accepting_entries',
  EntriesClosed = 'entries_closed',
  Ongoing = 'ongoing',
  Calculating = 'calculating',
  AnnouncingResults = 'announcing_results',
  EventFinished = 'event_finished',
}

const RankingEventProvider: FC<{ children: React.ReactElement }> = ({ children }) => {
  const [ongoingEvents, setOngoingEvents] = useState<Array<IRankingEvent>>([])
  const [joiningEvent, setJoiningEvent] = useState<IRankingEventCreator | null>(null)
  const [actionable, setActionable] = useState<IRankingEventActionable | null>(null)
  const { query } = useRouter()

  // 開催中のランキングイベントを取得
  const getOngoingRankingEvents = async (): Promise<Array<IRankingEvent>> => {
    const result = await fetchFanmeData(`/fanme/api/ranking/events`, { method: 'get' })
    if (result.status >= 400) {
      return []
    }
    setOngoingEvents(result.ranking_events)
    return result.ranking_events
  }

  // 参加中のランキングイベントを取得
  const getJoiningEvent = async (force: boolean): Promise<IRankingEventCreator | null> => {
    // ルートにクリエイターIDがない場合は無視
    if (!query.creator_id) return null
    // すでに取得済みでアカウントが変わっていない場合は取得済みのものを返す
    if (
      !force &&
      joiningEvent &&
      query.creator_id === `@${joiningEvent.creator.account_identity}`
    ) {
      return joiningEvent
    }
    const result = await fetchFanmeData(
      `/fanme/api/ranking/event/creator/${query.creator_id}/active`,
      { method: 'get' },
    )
    if (result.status >= 400) {
      return null
    }
    setJoiningEvent(result)
    return result
  }

  // 任意のランキングイベントを取得
  const getRankingEvent = async (eventIdentity: string): Promise<IRankingEvent | null> => {
    if (!eventIdentity) return null
    const result = await fetchFanmeData(`/fanme/api/ranking/event/${eventIdentity}`, {
      method: 'get',
    })
    if (result.status >= 400) {
      return null
    }
    return result
  }

  // クリエイターランキングを取得
  const getCreatorRanking = async (eventIdentity: string): Promise<Array<IRankingEventCreator>> => {
    if (!eventIdentity) return []
    const result = await fetchFanmeData(`/fanme/api/ranking/event/${eventIdentity}/creators`, {
      method: 'get',
    })
    if (result.status >= 400) {
      return []
    }
    return result.ranking_event_creators
  }

  // ユーザーランキングを取得
  const getUserRanking = async (
    eventIdentity: string,
    creatorId: string,
  ): Promise<Array<IRankingEventUser>> => {
    if (!eventIdentity) return []
    if (!creatorId) return []
    const result = await fetchFanmeData(
      `fanme/api/ranking/event/${eventIdentity}/creator/${creatorId}/users`,
      { method: 'get' },
    )
    if (result.status >= 400) {
      return []
    }
    return result.ranking_event_users
  }

  // アクション可否を取得
  const getActionable = async (
    eventIdentity: string,
    creatorId: string,
  ): Promise<IRankingEventActionable | null> => {
    setActionable(null)
    if (!eventIdentity || !creatorId) return null
    const result = await fetchFanmeData(
      `/fanme/api/creators/self/ranking/event/${eventIdentity}/creator/${creatorId}/actionable`,
      { method: 'get' },
    )
    if (result.status >= 400) {
      return null
    }
    setActionable(result)
    return result
  }

  // エールを登録
  const postYell = async (
    eventIdentity: string,
    creatorId: string,
    logType: number,
    count: number,
  ): Promise<number> => {
    if (!eventIdentity) return 0
    if (!creatorId) return 0
    if (![0, 2, 4].includes(logType)) return 0
    if (count == 0) return 0
    const result = await postBackend(
      `/fanme/api/creators/self/ranking/event/${eventIdentity}/creator/${creatorId}/yell`,
      { log_type: logType, count: count },
    )
    if (result.status >= 400) {
      return 0
    }
    return result.data.yell_count
  }

  // イベントに参加
  const partsicipateEvent = async (eventIdentity: string): Promise<boolean> => {
    if (!eventIdentity) return false
    const result = await postBackend(
      `fanme/api/creators/self/ranking/event/${eventIdentity}/partsicipate`,
      {},
    )
    if (result.status >= 400) {
      return false
    }
    return true
  }

  const getEventStatus = (event: IRankingEvent): RankingEventStatus => {
    const now = new Date()
    if (now < new Date(event.apply_start_at)) {
      return RankingEventStatus.Preparing
    } else if (now >= new Date(event.apply_start_at) && now < new Date(event.apply_end_at)) {
      return RankingEventStatus.AcceptingEntries
    } else if (now >= new Date(event.apply_end_at) && now < new Date(event.start_at)) {
      return RankingEventStatus.EntriesClosed
    } else if (now >= new Date(event.start_at) && now < new Date(event.end_at)) {
      return RankingEventStatus.Ongoing
    } else if (now >= new Date(event.end_at) && now < new Date(event.calculated_at)) {
      return RankingEventStatus.Calculating
    } else if (now >= new Date(event.calculated_at) && now < new Date(event.archived_at)) {
      return RankingEventStatus.AnnouncingResults
    } else if (now >= new Date(event.archived_at)) {
      return RankingEventStatus.EventFinished
    }
    return RankingEventStatus.Preparing
  }

  const generateRouteWithViewCreator = (
    baseUrl: string,
    activeViewCreatorId: string | null = null,
  ): string => {
    const [url, anchor] = baseUrl.split('#')
    let generatedUrl = url
    if (activeViewCreatorId) {
      generatedUrl = `${url}?view_creator=${activeViewCreatorId}`
    } else if (query.view_creator) {
      generatedUrl = `${url}?view_creator=${query.view_creator}`
    }
    return anchor ? `${generatedUrl}#${anchor}` : generatedUrl
  }

  // ランキングを取得
  const getRankingResult = async (eventIdentity: string): Promise<Array<IRankingEventResult>> => {
    if (!eventIdentity) return []
    const result = await fetchFanmeData(`/fanme/api/ranking/event/${eventIdentity}/results`, {
      method: 'get',
    })
    if (result.status >= 400) {
      return []
    }
    return result.ranking_event_results
  }

  const value = {
    ongoingEvents,
    joiningEvent,
    actionable,
    setActionable,
    getOngoingRankingEvents,
    getJoiningEvent,
    getRankingEvent,
    getCreatorRanking,
    getUserRanking,
    getActionable,
    postYell,
    partsicipateEvent,
    getEventStatus,
    generateRouteWithViewCreator,
    getRankingResult,
  }
  return <RankingEventContext.Provider value={value}>{children}</RankingEventContext.Provider>
}

export default RankingEventProvider
export const useRankingEvent = () => useContext(RankingEventContext)
