import React, { ReactNode } from 'react'
import FollowerProvider from '@/contexts/FollowerContext'
import RankingEventProvider from '@/contexts/RankingEventContext'

interface Props {
  component: ReactNode
  isLimitWidth: boolean
}

const AppComponent = ({ component, isLimitWidth }: Props) => {
  return (
    <FollowerProvider>
      <RankingEventProvider>
        <div
          data-testid="app-component"
          style={
            isLimitWidth
              ? {
                  maxWidth: '480px',
                  margin: '0px auto',
                  position: 'relative',
                }
              : {}
          }
        >
          {component}
        </div>
      </RankingEventProvider>
    </FollowerProvider>
  )
}

export default AppComponent
